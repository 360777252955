@mixin role($bg-color) {
	background-color: $bg-color;
}

.super_admin{
	@include role($color-red);
}

.admin{
	@include role($color-blue);
}

.user{
	@include role($color-grey);
}
