.lg_texte_court, .lg_texte_ok, .lg_texte_long{
  height:20px;
  font-size:12px;
  font-weight:bold;
  text-align:center;
  padding:4px 6px;
  color:#FFFFFF;
}

.lg_texte_court{
  background:#f1c40f;
}

.lg_texte_ok{
  background:#27ae60;
}

.lg_texte_long{
  background:#e74c3c;
}

.google_preview {

  #google_preview_title{
    color: #1a0dab;
    font-size: 20px;
    line-height: 1.3;
    padding: 4px 0 0 0;
    margin: 0 0 3px 0;
  }

  #google_preview_url{
    font-size: 14px;
    padding-top: 1px;
    line-height: 1.3;
    color: #3C4043;
    font-style: normal;
  }

  #google_preview_description{
    line-height: 1.58;
    word-wrap: break-word;
    color: #3C4043;
  }
}
