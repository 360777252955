@mixin fontFace($family,$src,$style: normal,$weight: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$src}.eot'); // IE9 compat
    src: url('#{$src}.eot?#iefix') format('embedded-opentype'), // IE8 and below
      url('#{$src}.woff') format('woff'), // standards
      url('#{$src}.ttf') format('truetype'), // Safari, Android, iOS
      url('#{$src}.svg##{$family}') format('svg'); // legacy iOS

    font-style: $style;
    font-weight: $weight;
  }
}
