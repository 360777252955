/*------------------------------------------------------------------
  [Colors]
*/
.clr-primary{       background-color: $primary-color; }
.clr-success{       background-color: $success-color; }
.clr-warning{       background-color: $warning-color; }
.clr-danger{        background-color: $danger-color; }
.clr-grey{          background-color: $grey-color; }


.bg-primary {
  @include bgVariant($primary-color);
}
.bg-red {
  @include bgVariant($color-red);
}
.bg-pink {
  @include bgVariant($color-pink);
}
.bg-purple {
  @include bgVariant($color-purple);
}
.bg-deep-purple {
  @include bgVariant($color-deep-purple);
}
.bg-indigo {
  @include bgVariant($color-indigo);
}
.bg-blue {
  @include bgVariant($color-blue);
}
.bg-light-blue {
  @include bgVariant($color-light-blue);
}
.bg-cyan {
  @include bgVariant($color-cyan);
}
.bg-teal {
  @include bgVariant($color-teal);
}
.bg-green {
  @include bgVariant($color-green);
}
.bg-light-green {
  @include bgVariant($color-light-green);
}
.bg-lime {
  @include bgVariant($color-lime);
}
.bg-yellow {
  @include bgVariant($color-yellow);
}
.bg-amber {
  @include bgVariant($color-amber);
}
.bg-orange {
  @include bgVariant($color-orange);
}
.bg-deep-orange {
  @include bgVariant($color-deep-orange);
}
.bg-brown {
  @include bgVariant($color-brown);
}
.bg-grey {
  @include bgVariant($color-grey);
}
.bg-blue-grey {
  @include bgVariant($color-blue-grey);
}
.bg-black {
  @include bgVariant($color-black);
}
.bg-white {
  @include bgVariant($color-white);
}

.text-primary {
  color: $primary-color;
}

.text-red {
  color: $color-red;
}

.text-pink {
  color: $color-pink;
}

.text-purple {
  color: $color-purple;
}

.text-deep-purple {
  color: $color-deep-purple;
}

.text-indigo {
  color: $color-indigo;
}

.text-blue {
  color: $color-blue;
}

.text-light-blue {
  color: $color-light-blue;
}

.text-cyan {
  color: $color-cyan;
}

.text-teal {
  color: $color-teal;
}

.text-green {
  color: $color-green;
}

.text-light-green {
  color: $color-light-green;
}

.text-lime {
  color: $color-lime;
}

.text-yellow {
  color: $color-yellow;
}

.text-amber {
  color: $color-amber;
}

.text-orange {
  color: $color-orange;
}

.text-deep-orange {
  color: $color-deep-orange;
}

.text-brown {
  color: $color-brown;
}

.text-grey {
  color: $color-grey;
}

.text-blue-grey {
  color: $color-blue-grey;
}

.text-black {
  color: $color-black;
}

.text-white {
  color: $color-white;
}
