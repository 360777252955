#mainNav {
  .navbar-collapse {
    overflow: auto;

    max-height: 75vh;
    .navbar-nav {
      .nav-item {
        .nav-link {
          cursor: pointer;
        }
      }
    }
    .navbar-sidenav {
      overflow-x: hidden;
      overflow-y: auto;

      .nav-link-collapse:after {
        float: right;

        @extend .fas;
        line-height: $line-height-base;
        content: fa-content($fa-var-angle-down);
      }
      .nav-link-collapse.collapsed:after {
        @extend .fas;
        line-height: $line-height-base;
        content: fa-content($fa-var-angle-right);
      }
      .sidenav-second-level,
      .sidenav-third-level {
        padding-left: 0;
        > li > a {
          display: block;

          padding: 0.5em 0;
          &:focus,
          &:hover {
            text-decoration: none;
          }
        }
      }
      .sidenav-second-level > li > a {
        padding-left: 1em;
      }
      .sidenav-third-level > li > a {
        padding-left: 2em;
      }
    }
    .sidenav-toggler {
      display: none;
    }
    .navbar-nav > .nav-item.dropdown {
      > .nav-link {
        position: relative;

        min-width: 45px;
        &:after {
          float: right;

          width: auto;

          @extend .fas;
          line-height: $line-height-base;
          content: fa-content($fa-var-angle-right);

          border: none;
        }
        .indicator {
          position: absolute;
          top: 5px;
          left: 21px;
          font-size: 10px;
        }
      }
      &.show > .nav-link:after {
        @extend .fas;
        line-height: $line-height-base;
        content: fa-content($fa-var-angle-down);
      }
      .dropdown-menu > .dropdown-item > .dropdown-message {
        overflow: hidden;

        max-width: none;

        text-overflow: ellipsis;
      }
    }
  }
  @media (min-width: 992px) {
    .navbar-brand {
      width: $sidenav-base-width;
    }
    .navbar-collapse {
      overflow: visible;

      max-height: none;
      .navbar-sidenav {
        position: absolute;
        top: 0;
        left: 0;

        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;

        margin-top: $navbar-base-height;

        > .nav-item {
          width: $sidenav-base-width;
          padding: 0;
          > .nav-link {
            padding: 1em;
          }
          .sidenav-second-level,
          .sidenav-third-level {
            padding-left: 0;

            list-style: none;

            > li {
              width: $sidenav-base-width;
              > a {
                padding: 1em;
              }
            }
          }
          .sidenav-second-level > li > a {
            padding-left: 2.75em;
          }
          .sidenav-third-level > li > a {
            padding-left: 3.75em;
          }
        }
      }
      .navbar-nav > .nav-item.dropdown {
        > .nav-link {
          min-width: 0;
          &:after {
            width: 24px;

            text-align: center;
          }
        }
        .dropdown-menu > .dropdown-item > .dropdown-message {
          max-width: 300px;
        }
      }
    }
  }
}
