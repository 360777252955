#page-builder{

  .drag-container{
    min-height: 200px;
    border: 2px dashed rgba(0, 0, 0, 0.3);
  }

  .drag-container:nth-child(odd) {
    border: 2px dashed rgba(0, 0, 0, 0.3);
  }

  .drag-container > div,
  .page-config {
    margin: 10px;
    padding: 10px;
    border:1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: opacity 0.4s ease-in-out;
  }
  .drag-container > div {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  .page-config {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
  .drag-container .ex-moved {
    background-color: #e74c3c;
  }
  .drag-container.ex-over {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .handle {
    cursor: move;
  }
}

.card-img-cms{
  max-height:185px;
}

.parallax{
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.heading-container{
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.heading-caption{
  position: absolute;
  text-align: center;
  top: 40%;
  width: 100%;
  left: -1%;
}
