// Paths
$img-path: '../../img';
$font-path: '../../fonts';
$components-path: '../../../../components';
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts/';

// General colors palette
$primary: #55838f;
$primary-color: $primary;

$dark-color: #3d3c3c;
$light-color: #ffffff;
$black-color: #000000;
$body-color: #404040;

// States Color
$danger-color: #ea4335;
$warning-color: #fbbc05;
$success-color: #34a853;
$grey-color: #cccccc;
$info-color: #4285f4;

// Icons colors
$icon-default-color: darken($light-color, 55%);
$icon-hover-color: darken($light-color, 75%);

// Color variations
@import 'colors.scss';

// General elements
$logo-width: 180px;
$logo-height: 27px;
$logo-padding: 20px;
$body-background: #eeeeee;
$content-padding: 25px;
$content-padding-tablet: 25px;
$content-padding-phone: 15px;
$content-padding-small-phone: 10px;
$link-color: lighten($primary-color, 10%);
$scroller-size: 6px; // Scroller plugin width

// Typography

$roboto: 'Roboto', Arial, sans-serif;

// Social colors
$facebook: #5d82d1;
$twitter: #50bff5;
$google-plus: #eb5e4c;
$dribbble: #f7659c;
$youtube: #ef4e41;
$flickr: #0063dc;
$linkedin: #238cc8;
$pinterest: #e13138;
$github: #333;
$tumblr: #426d9b;
$evernote: #2dbe60;
$dropbox: #007ee5;

// Spacing Variables
// Change below variable if the height of the navbar changes
$navbar-base-height: 56px;
// Change below variable to change the width of the sidenav
$sidenav-base-width: 250px;
// Change below variable to change the width of the sidenav when collapsed
$sidenav-collapsed-width: 55px;
// Change below variable to change the height of the sticky footer
$sticky-footer-height: 56px;
