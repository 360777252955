/*------------------------------------------------------------------
[Fonts - Roboto]
*/

@include fontFace('Roboto','../../../fonts/roboto/Roboto-Regular');
@include fontFace('RobotoItalic','../../../fonts/roboto/Roboto-RegularItalic', italic, normal);

@include fontFace('RobotoRegular','../../../fonts/roboto/Roboto-Regular', omrla, 400);
@include fontFace('RobotoRegularItalic','../../../fonts/roboto/Roboto-RegularItalic', italic, 400);

@include fontFace('RobotoThin','../../../fonts/roboto/Roboto-Thin', normal, 100);
@include fontFace('RobotoThinItalic','../../../fonts/roboto/Roboto-ThinItalic', italic, 100);

@include fontFace('RobotoLight','../../../fonts/roboto/Roboto-Light', normal, 300);
@include fontFace('RobotoLightItalic','../../../fonts/roboto/Roboto-LightItalic', italic, 300);

@include fontFace('RobotoMedium','../../../fonts/roboto/Roboto-Medium', normal, 500);
@include fontFace('RobotoMediumItalic','../../../fonts/roboto/Roboto-MediumItalic', italic, 500);

@include fontFace('RobotoBold','../../../fonts/roboto/Roboto-Bold', normal, 700);
@include fontFace('RobotoBoldItalic','../../../fonts/roboto/Roboto-BoldItalic', italic, 700);

@include fontFace('RobotoBlack','../../../fonts/roboto/Roboto-Black', normal, 900);
@include fontFace('RobotoBlackItalic','../../../fonts/roboto/Roboto-BlackItalic', italic, 900);
